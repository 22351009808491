
import { onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  name: "SocialSecurityNumber",
  setup(): void {
    const router = useRouter();
    const store = useStore();

    onMounted(() => {
      const currentUser = store.getters.getCurrentUser;
      const roles = currentUser.entitlements;

      if (roles.includes("Pnradministratör")) {
        router.push("/personal-identity-number/admin");
      } else if (roles.includes("Student")) {
        router.push("/personal-identity-number/student");
      } else {
        // Hantera andra roller eller visa ett felmeddelande
        router.push("/unauthorized");
      }
    });
  },
};
